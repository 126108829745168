<template>
	<div>
		<div
			class="container top flex-column justify-center"
		>
			<div class="justify-space-between items-center">
				<div class="flex-row justify-start items-center gap-30 ">
					<div>
					<button class="hide-pc mr-10" @click="onSide"><img :src="require('@/assets/images/icon/icon-hambuger.png')"/></button>
					<button class="" @click="toMain"><img :src="require(`@/assets/images${$skin.img}logo_top.png`)" class="logo-top" /></button>
					</div>
					<ul class="flex-row justify-end gap-30 hide-m">
						<li
							v-if="user.member_level > 0"
							class="text-18 text-vw-18"
						><button class="font-weight-bold" @click="toPoint">포인트 충전</button></li>
						<li
							class="text-18 text-vw-18"
						><button class="font-weight-bold" @click="toList('mobile')">모바일 상품몰</button></li>
						<li
							class="text-18 text-vw-18"
						><button class="font-weight-bold" @click="toList('ticket')">기타 상품몰</button></li>
						<li class="text-18 text-vw-18"><button class="font-weight-bold" @click="toList('')">일반 상품몰</button></li>
						<li class="text-18 text-vw-18"><button class="font-weight-bold" @click="toList('business')">기업 상품몰</button></li>
					</ul>
				</div>
				<div class="flex-row justify-end gap-10 lnb-m">
					<button
						v-if="!user || !user.member_id"
						class="text-16 text-vw-16 font-weight-500"
						@click="toLogin"
					>로그인</button>

					<button
						v-if="!user || !user.member_id"
						class="text-16 text-vw-16 font-weight-500"
						@click="toJoin"
					>회원가입</button>
					<button
						v-if="user && user.member_id"
						class="text-16 text-vw-16 font-weight-500 "
						@click="toCart"
					>장바구니</button>
					<button
						v-if="user && user.member_id"
						class="text-16 text-vw-16 font-weight-500 hide-m hide-tab"
						@click="toShopping"
					>주문현황</button>
					<button
						v-if="user && user.member_id"
						class="text-16 text-vw-16 font-weight-500 "
						@click="toMypage"
					>마이페이지</button>
					<button
						class="text-16 text-vw-16 font-weight-500 hide-m hide-tab"
						@click="toBoard('b_notice')"
					>고객센터</button>
					<button
						v-if="user && user.member_id"
						class="btn-round btn-outline btn-s hide-m hide-tab "
						@click="toLogout"
					>로그아웃</button>
				</div>
			</div>
		</div>
		<div
			v-if="is_point"
			class="bg-primary"
		>
			<div class="container-pc justify-space-between bg-point pa-20-m">
				<div class="flex-column justify-center color-white">
					<div class="size-px-24-pc size-px-14-m">Point Charge</div>
					<div class="size-px-48-pc size-px-24-m font-weight-bold">포인트 충전</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Top'
		, props: ['user', 'shop_info']
		, computed: {
			is_point: function(){
				let t = false
				if(this.$route.name && this.$route.name.indexOf('PointItem') > -1){
					t = true
				}
				return t
			}
		}
		,methods: {
			onSide: function(){
				this.$emit('toggleSide')
			}
			,toMain: function(){
				this.$bus.$emit('to', {name: 'Index', not_query: false})
			}
			, toPoint: function(){
				this.$bus.$emit('to', {name: 'PointItem', not_query: false})
			}
			, toList: function(pdt_div){
				if(pdt_div){
					this.$bus.$emit('to', {name: 'ProductList', params: { pdt_div: pdt_div}})
				}else{
					this.$bus.$emit('to', {name: 'ProductList'})
				}
			}
			, toLogin: function(){
				this.$bus.$emit('to', { name: 'Login'})
			}
			, toJoin: function(){
				this.$bus.$emit('to', { name: 'Join'})
			}
			, toMypage: function(){
				this.$bus.$emit('to', { name: 'MyPage'})
			}
			, toBoard: function(b_code){
				this.$bus.$emit('to', { name: 'CustomerCenter', params: { b_code: b_code }})
			}
			, toShopping: function(){
				this.$bus.$emit('to', { name: 'OrderList', params: { type: 'all'}})
			}
			, toCart: function(){
				this.$bus.$emit('to', { name: 'Cart', params: { pdt_div: 'business'}})
			}
			, toLogout: function(){
				if(confirm("로그아웃 하시겠습니까?")){
					this.$bus.$emit('logout')
				}
			}
		}
		,created() {
		}
	}
</script>

<style>
	.top {
		height: 109px;
	}
	.shop-logo { max-width: 300px; max-height: 100px }

	.bg-point {
		background-image: url('../../assets/images/main/money2.png');
		background-size: 35%;
		background-position: right calc(100% + 150px);
		height: 320px;
	}

	.logo-top { max-width: 130px}

	@media (max-width: 720px) {
		.top {
			height: 51px;
		}
		.logo-top {
			width: 60px;
		}

		.lnb-m button {
			font-size: 12px !important;
			font-weight: 600 !important;
			font-family: Pretendard;
		}

		.bg-point {
			background-size: 50%;
			height: 150px;
			background-position: calc(100% + 30px) calc(100% + 55px);
		}
	}
</style>